html,
body {
  padding: 0;
  margin: 0;
  background: #fff2f2 !important;
}

label,
button {
  cursor: pointer;
}

.single-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-text-overflow (@lines: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
}
