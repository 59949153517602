#loading {
  margin: 30px 0;
  justify-content: center;

  div {
    width: 4em;
    height: 4em;
    border: 4px solid #fff2f2;
    border-radius: 50%;
    border-top-color: #f09199;
    animation: spin .6s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
