@primary-color: #F09199;
@link-color: #F25D8E;
@border-radius-base: 4px;
@border-color-base: rgba(247, 188, 194, 0.5);
@box-shadow-base: 0 2px 4px rgba(0, 0, 0, 0.1);

.ant-message-notice-content {
  color: #f09199;
  border: 1px solid @border-color-base;
  box-shadow: @box-shadow-base;
}

@primary-color: #F09199;@link-color: #F25D8E;@border-radius-base: 4px;@border-color-base: rgba(247, 188, 194, 0.5);@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);